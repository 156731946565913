import React from 'react';
import {Helmet} from 'react-helmet';

import '../sass/main.scss';
import Header from './Header';
import Footer from './Footer';

export default function Body(props)  {
    let frontmatter = props.pageContext.frontmatter
    let siteData = props.pageContext.site.siteMetadata
    let seoData = props.pageContext.site.siteMetadata.seoData
    let title = (siteData.title !== undefined)? siteData.title : (frontmatter.title !== undefined)? frontmatter.title : 'Oppenberg' 
    let description = (siteData.description !== undefined)? siteData.description : (frontmatter.excerpt !== undefined)? frontmatter.excerpt : ""
    let keywords = seoData.keywords
    let image = seoData.metaImage
    let url = (props.pageContext.url !== undefined)? (props.pageContext.url).split('/')[1] : ''
    let finalURL = 'https://oppenberg.com/'+ url
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initialScale=1.0" />
                <link rel="icon" href="/favicon.ico"/>
                <meta name="description" content={description}/>
{/* Seo open graph tags */}
                <meta name="keywords" content={keywords}/>
                    <meta name="description" content={description}/>

                    <meta property="og:locale" content="en_IE" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={description}/>
                    <meta property="og:url" content={finalURL} />
                    <meta property="og:site_name" content="Oppenberg Capital: Structured Real Estate Finance"/>
                    <meta property="og:image" content={image}/>
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="627" />

                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:site" content="Oppenberg Capital: Structured Real Estate Finance" />
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:image" content={image}/>

                    <link rel="apple-touch-icon" sizes="57x57" href={image}/>
                    <link rel="apple-touch-icon" sizes="60x60" href={image}/>
                    <link rel="apple-touch-icon" sizes="72x72" href={image}/>
                    <link rel="apple-touch-icon" sizes="76x76" href={image}/>
                    <link rel="apple-touch-icon" sizes="114x114" href={image}/>
                    <link rel="apple-touch-icon" sizes="120x120" href={image}/>
                    <link rel="apple-touch-icon" sizes="144x144" href={image}/>
                    <link rel="apple-touch-icon" sizes="152x152" href={image}/>
                    <link rel="apple-touch-icon" sizes="180x180" href={image}/>
                    <link rel="icon" type="image/png" sizes="192x192"  href={(image === undefined)? "" : image}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={(image === undefined)? "" : image}/>
                    <link rel="icon" type="image/png" sizes="96x96" href={(image === undefined)? "" : image}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={(image === undefined)? "" : image}/>
                

            </Helmet>
            <div id="site-wrap" className="site">
                <Header {...props} />
                <main id="content" className="site-content">
                {props.children}
                </main>
                <Footer {...props} />
            </div>
        </React.Fragment>
    );
}
